import React from "react";
import {
  Switch,
  Route,
  useParams
} from "react-router-dom";
import ProgramListElement from '.././Components/ProgramListElement';
import ProgramSingle from './ProgramSingle';
import Breadcrumbs from '.././Components/Breadcrumbs';

function ProgramList() {
    document.title = "Programy - Radio Kampus 97,1FM #SAMESZTOSY";



    return (
        <div className="programs-section">
          <Breadcrumbs />
                <div className="content-header with-filter">
                  <h2>Programy</h2>
                </div>
          <div className="program-list-container">

           <Switch>

              <Route path="/programy/:programCategory/:programName">
                  <ProgramSingleContainer />
              </Route>

              <Route path="/programy/:programName">
                  <ProgramSingleContainer />
              </Route>

              <Route path="/programy">
                  {/*<Redirect to={`${match.path}/muzyka/`} />*/}
                  <ProgramListElementContainer />
              </Route>

		        </Switch>

         </div>
       </div>
    );
  }


function ProgramListElementContainer() {
    let { programCategory } = useParams();
    return (
      <div><ProgramListElement path={programCategory} /></div>
    )
}

function ProgramSingleContainer() {
    let { programName } = useParams();
    return (
      <div><ProgramSingle path={programName} /></div>
    )
}

export default ProgramList;