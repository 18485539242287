import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from "react-router-dom";
import PodcastListElement from '.././Components/PodcastListElement';
import PodcastSingle from './PodcastSingle';
import Breadcrumbs from '.././Components/Breadcrumbs';

function PodcastList() {

    let match = useRouteMatch();
    document.title = "Podcasty - Lista odcinków - Radio Kampus 97,1FM #SAMESZTOSY";
    return (

    <div className="podcasts-section">


        <Breadcrumbs />
        <div className="dynamic-boxes">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
        </div>
        <div className="content-header with-filter">
            <h2>Podcasty - najnowsze</h2>

        </div>
        <div className="podcast-list-container">

            <Switch>

                <Route path="/podcasty-lista/:podcastCategory/page:podcastCategory?">
                    <PodcastListElementContainer />
                </Route>

                <Route path="/podcasty-lista/:podcastCategory/:podcastName">
                    <PodcastSingle />
                </Route>

                <Route path="/podcasty-lista/page:podcastCategory?">
                    <PodcastListElementContainer />
                </Route>

                <Route path={`${match.path}/:podcastCategory`}>
                    <PodcastListElementContainer />
                </Route>

                <Route path="/podcasty-lista">
                    <PodcastListElementContainer />
                </Route>

            </Switch>

        </div>
    </div>
    );

}

function PodcastListElementContainer() {

    let { podcastCategory } = useParams();
    return (
        <div><PodcastListElement path={ podcastCategory } /></div>
    )

}

export default PodcastList;