import React from "react";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';


// function BreadcrumbTitleContainer() {
//   let { infoPageId } = window.;
//   return (
//     <div>
// <InfoPageElement path={infoPageId} /></div>
//     )
// }


class BreadcrumbTitle extends React.Component {

    state = {
      titledata: [],
      isLoading: true,
      errors: null,
    };

    getTitle() {

    	let str = this.props.path.replace('/', '');

        let path_name = (str[str.length - 1] === '/') ? str.slice(0, -1) : str;
        console.log(path_name);

    	if (!path_name) {
    		path_name = 'test';
    	}
        if (path_name === 'podcasty-lista') {
            path_name = 'podcasty';
        }


        // PAGE CATEGORY RULES




        axios
            .get(`${apiUrl}/${path_name}/?metadata=true`, {
                headers: {
                    // 'Content-Type': 'application/json'
                },

            })
            // Once we get a response and store data, let's change the loading state
            .then(response => {
                this.setState({
                    titledata: response.data,
                    isLoading: false
                });
            })
            // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    // window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
    }

    componentDidMount() {
        this.getTitle();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {

            this.setState({
                isLoading: true,
            });
            this.getTitle();

        }
    }

    render() {
        const { isLoading, titledata } = this.state;
        		let breadcrumb_title = '☻';
        		if(titledata.title) {
					// if(window.location.href.indexOf("page") > -1) {

					// 	//(this.href.substring(this.href.lastIndexOf('/') + 1));
     //    			breadcrumb_title = 'Strona';

					// } else {	breadcrumb_title = titledata.title; }
					breadcrumb_title = titledata.title;
        		}


                return (

                    <React.Fragment>
                        {!isLoading ? (
                            breadcrumb_title.toLowerCase()
                        ) : ( <span>☻</span> )}
                    </React.Fragment>
                );

    }
}


export default BreadcrumbTitle;