import React from "react";
import {
  Link
} from "react-router-dom";
import Pagination from './Pagination';
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class PodcastListElement extends React.Component {


    state = {
        podcasts: [],
        isLoading: true,
        errors: null,
      };

      getPosts() {
          let search_query = this.props.path;
          let page_query = this.props.page;
          let isKeywords = this.props.keywords ? this.props.keywords : false;
          console.log(isKeywords);
          let page_number;
          let page_category_query;
          let page_base_url = 'podcasty';



          // PAGE CATEGORY RULES
              console.log("TYP STRONY: " + page_base_url);
              let page_category_url_split = window.location.pathname.split("/");
                  // console.log(page_category_url_split[2]);
              let page_category_url_pop = page_category_url_split.pop();
                  // console.log(page_category_url_pop);
                  // let page_category_url_start = window.location.pathname.split("/").pop();
              if( !page_category_url_pop ) {
                  page_category_url_pop = page_category_url_split.pop();
              }

              var lastChar = window.location.pathname.substr(-1); // Selects the last character
                  if (lastChar !== '/') {         // If the last character is not a slash

                  }

              if ((window.location.pathname === '/'+page_base_url+'') || (window.location.pathname === '/'+page_base_url+'/')) {
                  // console.log("TAK");
                  page_category_query = '';
              } else if (document.location.pathname.indexOf('/'+page_base_url+'/page') === 0) {
                  page_category_query = '';
              } else {
                  // console.log("NIE");
                  // console.log(page_category_query);
                  let page_category_url;
                  if (window.location.pathname.indexOf('/page') >= 0) {
                  // console.log('TAAAAAAAAAAA');
                  if (window.location.pathname.indexOf('/podcasty/szukaj') === 0) {
                      page_category_url = window.location.pathname.split("/")[3];
                  } else {
                      page_category_url = window.location.pathname.split("/")[2];

                  }
                  console.log(page_category_url);
                  } else {
                      page_category_url = page_category_url_pop.replace(/\/$/, '');
                  }
                  page_category_query = page_category_url;
                  if (!isKeywords) {
                      console.log("KATEGORIA:" + page_category_url);
                  } else {
                      console.log("KEYWORDS:" + page_category_url);
                  }
              }

              // Check page URL
              if( window.location.pathname.indexOf('/page') >= 0) {
                  page_number = window.location.pathname.split("page")[1];
                  // console.log("Wybrana strona: " + page_number);
              } else {
                  page_number = '1';
                  // console.log("Strona poczatkowa")
              }
                      // END PAGE CATEGORY RULES
              if (page_category_query === 'sluchaj') {
                  // page_category_query = '/' + page_category_query;
                  page_category_query = '';
              }
          // END PAGE CATEGORY RULES
              if (page_category_query !== '') {
                  // page_category_query = '/' + page_category_query;
              }


              if (isKeywords) {
                  page_category_query += '&keywords';
            }


          axios
              .get(`${apiUrl}/podcasty/${page_number==='1'?'':'page'+page_number}?api=true&sort=-podcast_date_added`, {
                  headers: {
                      // 'Content-Type': 'application/json'
                  },

              })
              // Once we get a response and store data, let's change the loading state
              .then(response => {
                  this.setState({
                      podcasts: response.data,
                      isLoading: false
                  });
              })
              // If we catch any errors connecting, let's update accordingly
              .catch(error => {
                  console.log(error);
                      // 404
                      //  window.location.href = '/not-found';

                  this.setState({ error, isLoading: false } )
              });
      }

      componentDidMount() {
          this.getPosts();
      }

      componentDidUpdate(prevProps, prevState) {
          if (prevProps.path !== this.props.path) {

              this.setState({
                  isLoading: true,
              });
              this.getPosts();

          }
      }

      render() {
          let page_number;
          const { isLoading, podcasts } = this.state;

          let page_path = window.location.pathname.split('/page')[0];
              // console.log(page_path);
              // DUPLICATED PAGE NUMBER
          if( window.location.pathname.indexOf('/page') >= 0) {
              page_number = window.location.pathname.split('/page')[1];
              console.log("Wybrana strona: " + page_number);
          } else {
              page_number = '1';
              // console.log("Strona poczatkowa")
          }
              // END DUPLICATION
          console.log(podcasts[0] !== undefined);
          if (podcasts) {
              if (podcasts[0]) {
                  //    PAGINATION
                  //    console.log(podcasts);
                  //    console.log(podcasts[0].pages_total)
                  let number_of_pages = podcasts[0].pages_total;
                  const page_numbers = [];




                  ////////////////////////////////////////////////////////////////////////////////
                  Pagination( number_of_pages, page_numbers, page_number, page_path );
                  ////////////////////////////////////////////////////////////////////////////////




                  return (
                      <React.Fragment>
                          <div className="podcast-list">
                          {!isLoading ? (
                              (podcasts !== null) ? ( (podcasts.map(podcast => {

                                  const { id, name, title, podcast_image, podcast_categories, created } = podcast;
                                  // let timestamp = Number(new Date( created * 1000 ));
                                //   let date = new Date(timestamp).toLocaleDateString('pl-PL', {
                                //     month: 'numeric',
                                //     day: 'numeric',
                                //     year: 'numeric',
                                // });
                                  let date = new Date(created).toLocaleDateString('pl-PL', {
                                    month: 'numeric',
                                    day: 'numeric',
                                    year: 'numeric',
                                });
                               let urlPodcast = '/podcasty/sluchaj/' + name;
                                  return (
                                      <div className="podcast-item" key={id}><span className="podcast-date">{date}</span>
                                          <Link to={urlPodcast}><img src={podcast_image} className="podcast--image" alt="" /></Link>
                                          <h3 className="podcast--title"><Link to={urlPodcast}>{title}</Link></h3>
                                          <span className="podcasts-metadata">
                                              {((podcast_categories) ? <span className="podcast--tags">#{podcast_categories}</span> : '')}
                                          </span>
                                      </div>
                                  );

                              })) ) : (<p>Nie znaleziono odcinków.</p>)
                          ) : (
                              <p>Wczytywanie odcinków...</p>
                          )}
                          </div>
                          <ul className="pagination">
                              {page_numbers}
                          </ul>
                      </React.Fragment>
                  );

              } else {

                  return (
                      <React.Fragment>
                          <div className="podcast-list">
                          {!isLoading ? (
                              (podcasts !== null) ? ( (podcasts.map(podcast => {

                                  const { id, title, podcast_image, url, podcast_kategorie } = podcast;
                                  return (
                                      <div className="podcast-item" key={id}>
                                          <Link to={url}><img src={podcast_image} className="podcast--image" alt="" /></Link>
                                          <h3 className="podcast--title"><Link to={url}>{title}</Link></h3>
                                          <span className="podcasts-metadata">
                                              <span className="podcast--tags">#{podcast_kategorie}</span>
                                              <span className="podcast--category">#Podcasty</span>
                                          </span>
                                      </div>
                                  );

                              })) ) : (<p>Nie znaleziono odcinków.</p>)
                          ) : (
                              <p>Wczytywanie odcinków...</p>
                          )}
                          </div>
                      </React.Fragment>
                  );
              }
          } else {
              return <p>Nie znaleziono odcinków.</p>
          }
      }
  }


export default PodcastListElement;